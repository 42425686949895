import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Paragraph from '../components/blocks/Paragraph';
import PageTitle from '../components/blocks/PageTitle';

const AboutPage = ({ data }) => {

    return (
        <Layout>
            <HelmetDatoCms seo={data.datoCmsAbout.seoMetaTags}></HelmetDatoCms>
            <div className='py-lg space-y-md text-text'>
                <PageTitle>O nas</PageTitle>
                <Paragraph>
                    Good Label Recordings to nowa na polskim rynku oficyna wydawnicza. Na pierwszy ogień idzie 12’’ płyta winylowa, lecz GLR nie będzie ograniczać się tylko do tego rodzaju nośnika. Jesteśmy fanami ciekawej i dobrze wyprodukowanej twórczości oraz fascynatami fizycznych nośników audio-video.
                </Paragraph>
                <Paragraph>
                    W zależności od realizowanych pomysłów w naszym katalogu będą pojawiać się winyle, małe i duże, kasety magnetofonowe, płyty CD i DVD oraz VHSy. Co tu dużo pisać, wszystko przed nami i oczywiście przed Wami. Jeśli chodzi o repertuar, to zaczęliśmy od rapu, lecz mamy otwartą głowę i będziemy wydawać i promować, wszystko to co jest według nas ciekawe i godne uwagi.
                </Paragraph>
                <Link className="button-main" to="/">Home</Link>
            </div>
        </Layout>
    )
};

export default AboutPage;

export const query = graphql`
    query AboutPageQuery {
        datoCmsAbout {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
          }
    }
`